import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import React, { useState, useEffect } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import makeAnimated from 'react-select/animated';
import { FaTrash } from "react-icons/fa";
import Select from 'react-select';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Loadimg from './loader';
import axios from 'axios';

const REACT_APP_PRJECT = process.env.REACT_APP_PRJECT;
const REACT_APP_CATEGORY_PROJECT = process.env.REACT_APP_CATEGORY_PROJECT;
const REACT_APP_ProjectTechImage = process.env.REACT_APP_PRJECTTECHIMAGE;

const Project = () => {

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [projectcategory, setProjectCategory] = useState([]);
  const [projectTech, setProjectTech] = useState([]);
  const [selectProject, setSelectedProject] = useState(null);
  const [numField, setNumField] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [errors, setErrors] = useState({});

  const animatedComponents = makeAnimated();

  const [formData, setFormData] = useState({
    s_no: '',
    slug: '',
    bannerimg: null,
    title: '',
    description: '',
    category: '',
    link: '',
    technology: '',
    selectedtechnologyImg: [],
    projectmockupimg: []
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_PRJECT}all?page=${currentPage}`);
      setProjectData(response.data.allProjects);
      setTotalProjectCount(response.data.totalProjectCount);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchcategoryData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_CATEGORY_PROJECT}all`);
      setProjectCategory(response.data);
    } catch (error) {
      console.log('Error fetching Category:', error);
    }
  };

  const fetchTechData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_ProjectTechImage}all`);
      setProjectTech(response.data)
    } catch (error) {
      console.log('Error fetching TechImage Data', error);
    }
  };

  const getNextSerialNumber = () => {
    const existingSerialNumbers = projectData.map(item => item.s_no);
    const maxSerialNumber = Math.max(...existingSerialNumbers);
    return maxSerialNumber !== -Infinity ? maxSerialNumber + 1 : 1;
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);
    setFormData(prevFormData => ({
      ...prevFormData,
      [type]: [...(prevFormData[type] || []), ...files]
    }));
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: value
    }));
  };

  // Function to handle project creation
  const handleCreate = async () => {
    let hasError = false;
    const newErrors = {};
    const uploadData = new FormData();

    // Check required fields and update error state
    ['s_no', 'slug', 'title', 'technology', 'link'].forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        hasError = true;
      }
    });

    if (!formData.bannerimg) {
      newErrors['bannerimg'] = true;
      hasError = true;
    } else {
      uploadData.append('bannerimg', formData.bannerimg);
    }

    if (hasError) {
      setErrors(newErrors);
      setButtonLoader(false);
      return; // Prevent submission if required fields are empty
    }

    setButtonLoader(true);

    Object.entries(formData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // Handle arrays for multiple files
        value.forEach(file => {
          uploadData.append(key, file);
        });
      }
    });

    uploadData.append("s_no", formData.s_no);
    uploadData.append('slug', formData.slug);
    uploadData.append('title', formData.title);
    uploadData.append('description', formData.description);
    uploadData.append('category', formData.category);
    uploadData.append('link', formData.link);
    uploadData.append('technology', formData.technology);
    uploadData.append('selectedtechnologyImg', formData.selectedtechnologyImg);

    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await axios.post(`${REACT_APP_PRJECT}`, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${refreshToken}`
        }
      });
      if (response.status === 201) {
        Swal.fire({
          title: "Project has been successfully saved.",
          icon: "success"
        });
        // Clear form data after successful creation
        resetForm();
        setShowModal(false);
        fetchData(); // Update blog data after successful creation
        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        title: "Ohh! Failed to create Project.Please try again",
        text: error.response?.data?.message || error.message || "An error occurred",
        icon: "error"
      });
      console.log('Error Creating Project:', error);
    } finally {
      setButtonLoader(false); // Hide BeatLoader
    }
  };

  const options = projectTech.map(tech => ({
    value: tech.s_no,
    label: `${tech.title}`
  }));

  const handleEdit = (id) => {
    const selectProject = projectData.find(item => item._id === id);
    setSelectedProject(selectProject);
    setEditModal(true);
    setFormData({
      ...selectProject,
      s_no: selectProject.s_no,
      slug: selectProject.slug,
      title: selectProject.title,
      description: selectProject.description,
      category: selectProject.category,
      link: selectProject.link,
      technology: selectProject.technology,
      bannerimg: selectProject.bannerimg,
      selectedtechnologyImg: selectProject.selectedtechnologyImg || [],
      projectmockupimg: selectProject.projectmockupimg || []
    });
  };

  const handleModifyProject = async () => {
    setButtonLoader(true);
    const uploadData = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'bannerimg') {
        if (value) {
          uploadData.append('bannerimg', value);
        }
      } else if (key === 'projectmockupimg') {
        if (Array.isArray(value)) {
          value.forEach(item => {
            uploadData.append(key, item); // Append both existing URLs and new files
          });
        }
      } else {
        uploadData.append(key, value);
      }
    });

    try {
      const refreshToken = Cookies.get('refreshToken');
      const response = await axios.put(`${REACT_APP_PRJECT}${selectProject._id}`, uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${refreshToken}`
        }
      });
      if (response.status === 200) {
        Swal.fire({
          title: "Project has been successfully Updated",
          icon: "success"
        });
        resetForm();
        setEditModal(false);
        fetchData();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error modifying project:', error);
      Swal.fire({
        title: "Ohh! Failed to modify Project. Try again later!",
        text: error.response?.data?.message || error.message || "An error occurred",
        icon: "error"
      });
    } finally {
      setButtonLoader(false);
    }
  };

  const handleDelete = (id) => {
    confirmDelete(id);
  };

  const confirmDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const refreshToken = Cookies.get('refreshToken');
          const response = await axios.delete(`${REACT_APP_PRJECT}${id}`, {
            headers: {
              'Authorization': `Bearer ${refreshToken}`
            }
          });
          if (response.status === 200) {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          }
          fetchData();
          window.location.reload();
        } catch (error) {
          console.error('Error deleting Project:', error);
          Swal.fire({
            title: "Error!",
            text: error.response?.data?.message || error.message || "An error occurred",
            icon: "error"
          });
        }
      }
    });
  };

  const handleAddField = () => {
    setNumField(numField + 1);
  };

  const handleRemoveField = () => {
    if (numField > 1) {
      setNumField(numField - 1);
    }
  };

  const handleEditExistingImage = (index, imageType) => {
    const newProjectmockupimg = [...formData.projectmockupimg];
    newProjectmockupimg[index].projectmockupimg[imageType] = null;
    setFormData({ ...formData, projectmockupimg: newProjectmockupimg });
  };

  const handleRemoveExistingImage = (event, index, field) => {
    const newImage = event.target.files[0];
    const newProjectmockupimg = [...formData.projectmockupimg];
    newProjectmockupimg[index].projectmockupimg[field] = newImage;
    setFormData({ ...formData, projectmockupimg: newProjectmockupimg });
  };

  const renderPagination = () => {
    const pages = [];

    if (totalPages > 1 && !(totalPages === totalProjectCount && totalPages === currentPage)) {
      // Render the button for page 1
      pages.push(
        <button key={1} onClick={() => handlePageChange(1)} className={currentPage === 1 ? 'active' : 'pagination'}>
          1
        </button>
      );
    }

    for (let i = 2; i <= totalPages; i++) {
      pages.push(
        <button key={i} onClick={() => handlePageChange(i)} className={currentPage === i ? 'active' : 'pagination'}>
          {i}
        </button>
      );
    }

    return pages;
  };

  const resetForm = () => {
    const nextSerialNumber = getNextSerialNumber();
    setFormData({ s_no: nextSerialNumber, slug: '', bannerimg: null, title: '', description: '', category: '', link: '', technology: '', selectedtechnologyImg: [], projectmockupimg: [] });
    setNumField(1);
    setErrors({});
  };

  const handlePageChange = (page) => {
    const nextPage = Math.max(1, page);
    setCurrentPage(nextPage);
  };

  useEffect(() => {
    if (!loading) {
      const nextSerialNumber = getNextSerialNumber();
      setFormData(prevState => ({
        ...prevState,
        s_no: nextSerialNumber
      }));
    }
  }, [loading]);

  useEffect(() => {
    getNextSerialNumber();
    fetchcategoryData();
    fetchTechData();
    fetchData();
    resetForm();
  }, [currentPage]);

  return (
    <>
      {loading ? (
        <Loadimg
        />
      ) : (
        <>
          {/* Add New Project button */}
          <div className="relative pb-[100px]">
            <div type="btn" onClick={() => setShowModal(true)} className="absolute cursor-pointer hover:shadow-inner shadow-sm btn-secondary flex text-white right-0 mt-[15px] gap-2"><span><BsPlusLg /></span>Add Project
            </div>
          </div>
          <section className="project-sec">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative gap-8 ">
              {projectData.map((item) => (
                <div className="group relative rounded-3xl cursor-pointer overflow-hidden border border-transparent shadow  bg-white transition duration-500 hover:border-secondary hover:bg-secondary/20 dark:bg-gray-dark hover:scale-[1.06] hover:shadow-sm" data-aos="zoom-in" data-aos-duration="1000" key={item._id}>
                  <figure className="h-full max-h-[191px] overflow-hidden">
                    <img src={item.bannerimg} alt="" className="w-full h-full object-cover" />
                  </figure>
                  <div className="card-body p-5">
                    <div className="flex justify-between items-center text-sm font-bold">
                      <div className="div">
                        <h6 className='heading'>{item.title}</h6>
                        {projectcategory.filter(catItem => catItem.s_no === parseInt(item.category)).map(filteredItem => (
                          <p key={filteredItem.s_no} className='dec'>{filteredItem.name}</p>
                        ))}
                      </div>
                      {/* Display technology image */}
                      {item.selectedtechnologyImg.length > 0 && (
                        <figure key={item.selectedtechnologyImg[0]}>
                          {projectTech
                            .filter(techItem => techItem.s_no === parseInt(item.selectedtechnologyImg[0]))
                            .map(filteredItem => (
                              <img className="max-w-[37px]" src={filteredItem.techimage} alt={filteredItem.label} key={filteredItem.s_no} />
                            ))}
                        </figure>
                      )}
                    </div>
                  </div>
                  <ul className="absolute inset-x-0 -bottom-full flex items-center justify-center gap-10 pb-5 transition-all duration-300 group-hover:inset-y-0 group-hover:bg-gradient-to-t group-hover:from-white/70">
                    <li className='flex justify-center w-2 h-2'>
                      <button type="button" onClick={() => handleEdit(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium bottom-6 text-[white] bg-[black] rounded-full p-2 hover:scale-125 transition-all duration-500 hover:underline">
                        <BsFillPencilFill />
                      </button>
                    </li>
                    <li className='flex justify-center w-2 h-2'>
                      <button type="button" onClick={() => handleDelete(item._id)} data-modal-target="editUserModal" data-modal-show="editUserModal" className="flex absolute font-medium bottom-6 text-[white] bg-[black] rounded-full p-2 hover:scale-125 transition-all duration-500 hover:underline">
                        <FaTrash />
                      </button>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </section>
          <div className="Pageination pt-4">
            <div className=' flex gap-2 justify-center text-white '>{renderPagination()}</div>
          </div>
        </>
      )
      }
      {
        showModal ? (
          <div className="justify-center items-center bg-[#000000a8] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
            <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h1 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                    Create Project
                  </h1>
                  <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => { setShowModal(false); resetForm(); }} >
                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 text-white ${errors.bannerimg ? 'text-red-500' : 'text-white'}`}>
                        Cover / banner Image*
                      </label>
                      <input className={`block w-full p-0.5 mb-0.5 text-gray-900 border-2 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400  ${errors.bannerimg ? 'border-red-500' : 'border-gray-300'}`} type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} required />
                    </div>
                    <div className="" >
                      <label className='block text-sm font-semibold mb-2 text-white'>Category</label>
                      <div className='w-full justify-between text-[black] bg-[white] font-medium rounded p-2 text-sm inline-flex items-center'>
                        <select
                          className='w-full dark:bg-[transparent] focus-visible:outline-none'
                          name="category"
                          id="category"
                          value={formData.category}
                          onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                        >
                          {projectcategory.map((item) => (
                            <option key={item.s_no} value={item.s_no}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} htmlFor="serial no.">
                        Serial No.*
                      </label>
                      <input
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                        type="number"
                        id="s_no"
                        name="s_no"
                        value={formData.s_no}
                        placeholder='Serial no'
                        onChange={(e) => {
                          setFormData({ ...formData, s_no: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.slug ? 'text-red-500' : 'text-white'}`} htmlFor="slug">
                        Slug*
                      </label>
                      <input
                        className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.slug ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        name="slug"
                        id="slug"
                        value={formData.slug}
                        placeholder='https://codebeans.net/portfolio/"slug"'
                        onChange={(e) => {
                          setFormData({ ...formData, slug: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                        Title*
                      </label>
                      <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Name of Project"
                        value={formData.title}
                        onChange={(e) => {
                          setFormData({ ...formData, title: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className="block text-sm font-semibold mb-2 text-white" htmlFor="linkedinid">
                        Description
                      </label>
                      <input className="shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        id="description"
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.technology ? 'text-red-500' : 'text-white'}`} htmlFor="technology">
                        Tech stack*
                      </label>
                      <textarea
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.technology ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        id="technology"
                        name="technology"
                        placeholder="Like: Development, Design, App Design + Development"
                        value={formData.technology}
                        onChange={(e) => {
                          setFormData({ ...formData, technology: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.link ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                        Project Link*
                      </label>
                      <input className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.link ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        id="link"
                        name="link"
                        placeholder="Put Link of Project"
                        value={formData.link}
                        onChange={(e) => {
                          setFormData({ ...formData, link: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className="block text-sm font-semibold mb-2">Select Technology Images</label>
                      <Select
                        value={options.value}
                        className="text-[black]"
                        onChange={(selectedOptions) => setFormData({ ...formData, selectedtechnologyImg: selectedOptions.map(option => option.value) })}
                        components={animatedComponents}
                        options={options}
                        isMulti
                        placeholder="Select Technology Images"
                      />
                    </div>
                    {
                      [...Array(numField)].map((_, index) => (
                        <React.Fragment key={index}>
                          <div className=''>
                            <label className="block text-black text-semi-bold mb-1" >
                              Mockup Image
                            </label>
                            <input
                              id={`fileInput-${index}`}
                              className='block w-full p-0.5 mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400'
                              type="file"
                              accept="image/*"
                              onChange={(event) => handleFileChange(event, 'projectmockupimg')}
                            />
                          </div>
                        </React.Fragment>
                      ))
                    }
                  </div>
                  <div className="mt-6 flex gap-3 justify-center">
                    <button
                      className="btn-primary flex"
                      onClick={handleAddField}><span>Add Field</span>
                    </button>
                    <button
                      className="btn-secondary flex"
                      onClick={handleRemoveField}><span className='text-black'>Remove Field</span>
                    </button>
                  </div>
                  <div className="flex justify-center mt-5">
                    <button type='Submit' className="btn-submit btn text-center text-white p-5 rounded" onClick={() => { setButtonLoader(true); handleCreate() }}>
                      {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div >
        ) : null
      }

      {
        selectProject && editModal ? (
          <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" data-aos="zoom-out" data-aos-duration="500">
            <div className="relative rounded-lg overflow-hidden sm:w-[90%] md:w-[90%] lg:max-w-2xl xl:lg:max-w-2xl md:max-w-2xl sm:max-w-2xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative text-white flex flex-col w-auto bg-[#374151] outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 rounded-t">
                  <h1 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                    Edit
                  </h1>
                  <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => { setEditModal(false); resetForm(); }} >
                    <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 pb-6 flex-auto h-[38rem] overflow-y-scroll overflow-hidden">
                  <div className="grid grid-cols-2 gap-2">
                    <div className="">
                      <figure className='w-16 rounded-md overflow-hidden'>
                        <img className='h-full w-full object-cover overflow-hidden' src={selectProject.bannerimg} alt=''></img>
                      </figure>
                      <label className='block text-sm font-semibold mb-2 ${errors.bannerimg text-white'>
                        Cover / banner Image*
                      </label>
                      <input className='block w-full p-0.5 mb-0.5 text-gray-900 border rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400 border-gray-300' type="file" accept="image/*" onChange={(e) => setFormData({ ...formData, bannerimg: e.target.files[0] })} required />
                    </div>
                    <div className="flex items-end">
                      <div className="div w-full">
                        <label className="block text-sm font-semibold mb-2 text-white">Category </label>
                        <div className="w-full justify-between text-[black] bg-[white] rounded p-2 inline-flex items-center">
                          <select
                            className='w-full dark:bg-[transparent] focus-visible:outline-none'
                            name="category"
                            id="category"
                            value={formData.category}
                            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                          >
                            {projectcategory.map((item) => (
                              <option key={item.s_no} value={item.s_no}>{item.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.s_no ? 'text-red-500' : 'text-white'}`} htmlFor="serial no.">
                        Serial No.*
                      </label>
                      <input
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.s_no ? 'border-red-500' : 'border-gray-300'}`}
                        type="number"
                        id="s_no"
                        name="s_no"
                        value={formData.s_no}
                        placeholder='Serial no'
                        onChange={(e) => {
                          setFormData({ ...formData, s_no: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.slug ? 'text-red-500' : 'text-white'}`} htmlFor="slug">
                        Slug*
                      </label>
                      <input
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.slug ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        name="slug"
                        id="slug"
                        value={formData.slug}
                        placeholder='https://codebeans.net/portfolio/"slug"'
                        onChange={(e) => {
                          setFormData({ ...formData, slug: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.title ? 'text-red-500' : 'text-white'}`} htmlFor="title">
                        Title*
                      </label>
                      <textarea
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.title ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Name of Project"
                        value={formData.title}
                        onChange={(e) => {
                          setFormData({ ...formData, title: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className="block text-sm font-semibold mb-2 text-white" htmlFor="linkedinid">
                        Description
                      </label>
                      <textarea
                        className="shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        id="description"
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.technology ? 'text-red-500' : 'text-white'}`} htmlFor="technology">
                        Tech stack*
                      </label>
                      <textarea
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.technology ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        id="technology"
                        name="technology"
                        placeholder="Like: Development, Design, App Design + Development"
                        value={formData.technology}
                        onChange={(e) => {
                          setFormData({ ...formData, technology: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className={`block text-sm font-semibold mb-2 ${errors.link ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                        Project Link*
                      </label>
                      <textarea
                        className={`'shadow appearance-none border-2 rounded w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.link ? 'border-red-500' : 'border-gray-300'}`}
                        type="text"
                        id="link"
                        name="link"
                        placeholder="Put Link of Project"
                        value={formData.link}
                        onChange={(e) => {
                          setFormData({ ...formData, link: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="">
                      <label className="block text-sm font-semibold mb-2">Select Technology Images</label>
                      <Select
                        className="text-[black]"
                        defaultValue={formData.selectedtechnologyImg.map(index => options[index])}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(option => option.value);
                          setFormData({ ...formData, selectedtechnologyImg: selectedValues });
                        }}
                        components={animatedComponents}
                        options={options}
                        isMulti
                        placeholder="Select Technology Images"
                      />
                    </div>
                    <div className="">
                      {selectProject.projectmockupimg && selectProject.projectmockupimg.length > 0 && (
                        <>
                          <label className="block text-black text-semi-bold mb-1" >
                            Existing Mockup Image
                          </label>
                          <div className='grid grid-cols-4 gap-2'>
                            {selectProject.projectmockupimg.map((img, index) => (
                              <label key={index} className='flex relative justify-center' htmlFor={`fileInput-${index}`}>
                                <label className='text-blue-500 absolute right-0 cursor-pointer'>
                                  <input
                                    className='hidden'
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleEditExistingImage(e, index, 'projectmockupimg')}
                                  />
                                  <BsFillPencilFill />
                                </label>
                                <figure className="w-12 h-12 my-1 rounded-md overflow-hidden">
                                  <img className='h-full w-full object-cover overflow-hidden' src={typeof img === 'string' ? img : URL.createObjectURL(img)} alt={`projectmockupimg ${index}`} />
                                </figure>
                                <button className='text-red-500 absolute bottom-1.5 items-center' type="button" onClick={() => handleRemoveExistingImage('projectmockupimg', index)}><FaTrash /></button>
                              </label>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    {[...Array(numField)].map((_, index) => (
                      <React.Fragment key={index}>
                        <div className='transition duration-500 ease-in-out'>
                          <label className="block text-black text-semi-bold mb-2">New Mockup Image</label>
                          <input
                            id={`fileInput-${index}`}
                            className='block w-full p-0.5 mb-0.5 text-gray-900 border rounded border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:border-gray-600 dark:placeholder-gray-400'
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleFileChange(event, 'projectmockupimg')}
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="mt-5 flex gap-3 justify-center">
                    <button
                      className="btn-primary flex"
                      onClick={handleAddField}><span>Add Field</span>
                    </button>
                    <button
                      className="btn-secondary flex"
                      onClick={handleRemoveField}><span className='text-black'>Remove Field</span>
                    </button>
                  </div>
                  <div className="flex justify-center">
                    <button className="btn-submit btn text-center text-white p-5 rounded mt-5" onClick={() => { setButtonLoader(true); handleModifyProject() }}>
                      {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div >
        ) : null
      }
    </>
  );
};

export default Project;
