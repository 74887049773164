import { BsFillPencilFill, BsPlusLg } from "react-icons/bs";
import PulseLoader from "react-spinners/PulseLoader";
import React, { useState, useEffect } from 'react';
import { FaTrash } from "react-icons/fa";
import Loading from './loader';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import axios from "axios";

const REACT_APP_ALLCONTECTLIST = process.env.REACT_APP_ALLCONTECTLIST;
const REACT_APP_ALLSUBLIST = process.env.REACT_APP_ALLSUBLIST;

const ContectList = () => {

    const [contectLists, setContectLists] = useState([]);
    const [subLists, setSubLists] = useState([]);
    const [buttonloader, setButtonLoader] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedsubId, setSelectedSubId] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [subeditModal, setsubEditModal] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('dateDesc');
    const [subsortBy, setSubSortBy] = useState('datesubDesc');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [contectList, setcontectList] = useState({
        name: '',
        email: '',
        mobile: '',
        city: '',
        message: '',
    });

    const [subList, setSubList] = useState({ subemail: '' });

    const fetchData = async () => {
        try {
            const res = await axios(`${REACT_APP_ALLCONTECTLIST}all`);
            const dataWithSerialNo = res.data.map((item, index) => ({
                ...item,
                serialNo: index + 1
            }));
            setContectLists(dataWithSerialNo);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const subfetchData = async () => {
        try {
            const res = await axios(`${REACT_APP_ALLSUBLIST}all`);
            const dataWithSerialNo = res.data.map((item, index) => ({
                ...item,
                serialNo: index + 1
            }));
            setSubLists(dataWithSerialNo);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleSort = (option) => {
        setSortBy(option);
    };

    const handlesubSort = (option) => {
        setSubSortBy(option);
    };

    const sortedContectLists = [...contectLists].sort((a, b) => {
        switch (sortBy) {
            case 'dateAsc':
                return new Date(a.createdAt) - new Date(b.createdAt);
            case 'dateDesc':
                return new Date(b.createdAt) - new Date(a.createdAt);
            case 'nameAsc':
                return a.name.localeCompare(b.name);
            case 'nameDesc':
                return b.name.localeCompare(a.name);
            default:
                return 0;
        }
    });

    const sortedSubLists = [...subLists].sort((l, r) => {
        switch (subsortBy) {
            case 'datesubAsc':
                return new Date(l.createdAt) - new Date(r.createdAt);
            case 'datesubDesc':
                return new Date(r.createdAt) - new Date(l.createdAt);
            case 'namesubAsc':
                return l.subemail.localeCompare(r.subemail);
            case 'namesubDesc':
                return r.subemail.localeCompare(l.subemail);
            default:
                return 0;
        }
    });

    const handleEdit = (id) => {
        const selectedData = contectLists.find(item => item._id === id);
        setSelectedId(selectedData._id); // Ensure you set the ID correctly
        setcontectList({
            name: selectedData.name,
            email: selectedData.email,
            mobile: selectedData.mobile,
            city: selectedData.city,
            message: selectedData.message,
        });
        setEditModal(true);
    };

    const subhandleEdit = (id) => {
        const selectedData = subLists.find(item => item._id === id);
        setSelectedSubId(selectedData._id)
        console.log("selectedData", selectedData)
        setSubList({ subemail: selectedData.subemail });
        setsubEditModal(true);
    };

    const subhandleEditing = async () => {
        let hasError = false;
        const newErrors = {};
        ['subemail'].forEach((field) => {
            if (!subList[field]) {
                newErrors[field] = 'This field is required';
                hasError = true;
            }
        });
        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return;
        }
        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_ALLSUBLIST}${selectedsubId}`, subList, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response === 201) {
                Swal.fire({ title: `${response.data.message}`, icon: "success" });
                resetForm();
                setsubEditModal(false);
                subfetchData()
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({ title: `${error.response.data.errors}`, icon: "error" });
            resetForm();
            setsubEditModal(false);
            subfetchData()
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleEditing = async () => {
        let hasError = false;
        const newErrors = {};
        ['name', 'email', 'mobile', 'city', 'message'].forEach((field) => {
            if (!contectList[field]) {
                newErrors[field] = 'This field is required';
                hasError = true;
            }
        });
        if (hasError) {
            setErrors(newErrors);
            setButtonLoader(false);
            return;
        }
        setButtonLoader(true);

        try {
            const refreshToken = Cookies.get('refreshToken');
            const response = await axios.put(`${REACT_APP_ALLCONTECTLIST}${selectedId}`, contectList, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                },
            });
            if (response.status === 200) {
                Swal.fire({ title: `${response.data.message}`, icon: "success" });
                resetForm();
                setEditModal(false);
                fetchData();
                window.location.reload();
            }
        } catch (error) {
            Swal.fire({ title: `${error.response.data.errors}`, icon: "error" });
            resetForm();
            setEditModal(false);
            fetchData();
        } finally {
            setButtonLoader(false);// Hide BeatLoader
        }
    };

    const handleDelete = (id) => {
        confirmDelete(id);
    };

    const subhandleDelete = (id) => {
        subconfirmDelete(id);
    }

    const confirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_ALLCONTECTLIST}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 201) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        fetchData();
                        window.location.reload()
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        });
    };

    const subconfirmDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCloseButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const refreshToken = Cookies.get('refreshToken');
                    const response = await axios.delete(`${REACT_APP_ALLSUBLIST}${id}`, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });
                    if (response.status === 204) {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                        subfetchData();
                        window.location.reload()
                    }
                } catch (error) {
                    console.error('Error While deleteing:', error);
                    Swal.fire({
                        title: "Error!",
                        text: error.response?.data?.message || error.message || "An error occurred",
                        icon: "error"
                    });
                }
            }
        });
    };

    const resetForm = () => {
        setcontectList({
            name: '',
            email: '',
            mobile: '',
            city: '',
            message: '',
        })
        setSubList({ subemail: '' })
    }

    useEffect(() => {
        fetchData();
        subfetchData();
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="flex flex-col">
                        <div className="inline-block lg:flex [&>*]:dark:text-white [&>*]:items-center gap-10">
                            <div className="flex gap-3">
                                <h2>Sorting</h2>
                                <select className="rounded-[10px] px-3 text-white bg-primary-500 dark:bg-secondary-400 focus-visible:outline-none" onChange={(e) => handleSort(e.target.value)} value={sortBy}>
                                    <option value="dateDesc">Newest to Oldest</option>
                                    <option value="dateAsc">Oldest to Newest</option>
                                    <option value="nameAsc">A-Z</option>
                                    <option value="nameDesc">Z-A</option>
                                </select>
                            </div>
                            <div className="flex gap-3">
                                <h2>Sorting by Quantity</h2>
                                <select className="rounded-[10px] px-3 bg-primary-500 dark:bg-secondary-400 text-white focus-visible:outline-none" onChange={(e) => setItemsPerPage(Number(e.target.value))} value={itemsPerPage}>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <div className="relative rounded overflow-x-auto py-2">
                            <table className="w-full text-left text-sm font-light dark:bg-[#343434] [&>*]:dark:text-white border-2 dark:border-none rounded-md sh">
                                <thead className="border-b font-medium dark:border-neutral-500">
                                    <tr>
                                        <th scope="col" className="px-6 py-4">Serial No.</th>
                                        <th scope="col" className="px-6 py-4">Title</th>
                                        <th scope="col" className="px-6 py-4">Email</th>
                                        <th scope="col" className="px-6 py-4">Mobile No.</th>
                                        <th scope="col" className="px-6 py-4">City</th>
                                        <th scope="col" className="px-6 py-4">Message</th>
                                        <th scope="col" className="px-6 py-4 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedContectLists.slice(0, itemsPerPage).map((item, index) => (
                                        <tr className="border-b [&]:last:dark:border-none transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600 [&>*]:font-normal [&>*]>:text-md" key={item._id}>
                                            <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{item.name}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{item.email}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{item.mobile}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{item.city}</td>
                                            <td className="whitespace-nowrap px-6 py-4 text-wrap white-space ">{item.message}</td>
                                            <td className="whitespace-nowrap px-6 py-4 flex justify-center items-center gap-2">
                                                <span onClick={() => handleEdit(item._id)}> <BsFillPencilFill className="text-blue-500 transition-all duration-300 hover:scale-125 cursor-pointer" /></span>
                                                <span onClick={() => handleDelete(item._id)}><FaTrash className="text-red-500 transition-all duration-300 hover:scale-125 cursor-pointer ml-2" /></span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <h2 className="shrink font-bold hover:text-navy-700 dark:hover:text-white text-[30px] lg:text-[45px] xl:text-[45px] 2xl:text-[45px] 3xl:text-[45px] 4xl:text-[45px] capitalize text-navy-700 dark:text-white py-3">Subscribe List </h2>
                        <div className="flex flex-col [&>*]:items-center [&>*]:dark:text-white">
                            <div className="flex gap-3">
                                <h2>Sorting</h2>
                                <select onChange={(e) => handlesubSort(e.target.value)} value={subsortBy} className="rounded-[10px] px-3 text-white bg-primary-500 dark:bg-secondary-400 focus-visible:outline-none">
                                    <option value="datesubDesc">Newest to Oldest</option>
                                    <option value="datesubAsc">Oldest to Newest</option>
                                    <option value="namesubAsc">A-Z</option>
                                    <option value="namesubDesc">Z-A</option>
                                </select>
                            </div>
                            <div className="relative rounded overflow-x-auto py-2">
                                <table className="w-full text-left text-sm font-light dark:bg-[#343434] [&>*]:dark:text-white border-2 dark:border-none rounded-md ">
                                    <thead className="border-b font-medium dark:border-neutral-500">
                                        <tr>
                                            <th scope="col" className="px-6 py-4">Serial No.</th>
                                            <th scope="col" className="text-center px-6 py-4">Subscriber Email</th>
                                            <th scope="col" className="px-6 py-4 text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedSubLists.slice(0, itemsPerPage).map((item, index) => (
                                            <tr className="border-b [&]:last:dark:border-none transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600 [&>*]:font-normal [&>*]>:text-md" key={item._id}>
                                                <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}</td>
                                                <td className="whitespace-nowrap text-center px-6 py-4">{item.subemail}</td>
                                                <td className="whitespace-nowrap px-6 py-4 flex justify-end items-center text-end gap-2">
                                                    <span onClick={() => subhandleEdit(item._id)}> <BsFillPencilFill className="text-blue-500 transition-all duration-300 hover:scale-125 cursor-pointer" /></span>
                                                    <span onClick={() => subhandleDelete(item._id)}><FaTrash className="text-red-500 transition-all duration-300 hover:scale-125 cursor-pointer ml-2" /></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {
                selectedId && editModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                        Edit
                                    </h4>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setEditModal(false); resetForm(); }} >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none"> × </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 pb-6 flex-auto">
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.name ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                                Title*
                                            </label>
                                            <input
                                                className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={contectList.name} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setcontectList({ ...contectList, name: e.target.value })} />
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.email ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                                Email*
                                            </label>
                                            <input
                                                className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={contectList.email} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setcontectList({ ...contectList, email: e.target.value })} />
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.mobile ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                                Mobile*
                                            </label>
                                            <input
                                                className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.mobile ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={contectList.mobile} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setcontectList({ ...contectList, mobile: e.target.value })} />
                                        </div>
                                        <div className="div">
                                            <label className={`block text-sm font-semibold mb-2 ${errors.city ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                                City*
                                            </label>
                                            <input
                                                className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.city ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={contectList.city} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setcontectList({ ...contectList, city: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="div my-2">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.message ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                            Message*
                                        </label>
                                        <textarea
                                            className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={contectList.message} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setcontectList({ ...contectList, message: e.target.value })} />
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button onClick={() => { setButtonLoader(true); handleEditing() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            {
                selectedsubId && subeditModal ? (
                    <div className="justify-center items-center bg-[#00000047] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" data-aos="zoom-out" data-aos-duration="500">
                        <div className="relative overscroll-y-auto sm:w-[80%] md:w-[80%] max-h-full my-6 mx-auto lg:max-w-xl xl:max-w-xl md:max-w-xl sm:max-w-xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative text-white flex flex-col w-auto bg-[#374151]  outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <h4 className='font-bold text-secondary-300 dark:text-secondary-400 text-4xl'>
                                        Edit
                                    </h4>
                                    <button className="p-1 ml-auto bg-transparent border-0 text-white absolute right-[10px] top-[5px] text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => { setsubEditModal(false); resetForm(); }} >
                                        <span className="bg-transparent text-white h-6 w-6 text-4xl block outline-none focus:outline-none"> × </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 pb-6 flex-auto">
                                    <div className="div my-2">
                                        <label className={`block text-sm font-semibold mb-2 ${errors.subemail ? 'text-red-500' : 'text-white'}`} htmlFor="link">
                                            Title*
                                        </label>
                                        <input
                                            className={`'shadow appearance-none border-2 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.subemail ? 'border-red-500' : 'border-gray-300'}`} id="link" type="text" value={subList.subemail} placeholder="Add Your ProjectTechImage Link" onChange={(e) => setSubList({ ...subList, subemail: e.target.value })} />
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button onClick={() => { setButtonLoader(true); subhandleEditing() }} className="btn-submit btn text-center text-white p-5 rounded">
                                            {buttonloader ? (<PulseLoader color="#fdfdfd" size={10} speedMultiplier={0.5} />) : ('Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
};

export default ContectList;